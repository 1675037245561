window.WOW = require('./wow')

$(window).on('load', function() {
  $('.home_main_slider').slick({
    dots: true,
    infinite: true,
    speed: 600,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 3500,
  })

  $('.industry_slider').slick({
    dots: true,
    infinite: true,
    speed: 600,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: true,
  })

  $('.news_slider_home_content').slick({
    dots: true,
    infinite: true,
    speed: 600,
    fade: true,
    cssEase: 'ease',
    autoplay: true,
    autoplaySpeed: 3500,
    settings: {
      arrows: true,
    },
  })

  $('.menu_icon').click(function() {
    $('.haeder_menu').addClass('open')
    $('.flag_menu_icon').fadeOut(200)
    $('.overlay_menu').fadeIn(200)
  })

  $('.close_icon').click(function() {
    $('.haeder_menu').removeClass('open')
    $('.flag_menu_icon').fadeIn(200)
    $('.overlay_menu').fadeOut(200)
  })

  $('.overlay_menu').click(function() {
    $('.haeder_menu').removeClass('open')
    $('.flag_menu_icon').fadeIn(200)
    $('.overlay_menu').fadeOut(200)
  })

  $('.od_fruit_slider').slick({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    cssEase: 'linear',
    autoplaySpeed: 3500,
  })

  $('.products_slider_home_content').slick({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    cssEase: 'linear',
    autoplaySpeed: 5200,
  })

  $('.carousel_about_us').slick({
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    variableWidth: true,
    autoplaySpeed: 1500,
    cssEase: 'linear',
    settings: {
      arrows: false,
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })

  // Header Fixed Home
  $(window).scroll(function() {
    let scroll = $(window).scrollTop()

    if (scroll >= 150) {
      $('.home_header').addClass('fillHeader')
    } else {
      $('.home_header').removeClass('fillHeader')
    }
  })

  // WOW
  wow = new WOW({
    animateClass: 'animated',
    offset: 150,
  })
  wow.init()

  // Counter
  $.fn.isOnScreen = function() {
    var win = $(window)

    var viewport = {
      top: win.scrollTop(),
      left: win.scrollLeft(),
    }
    viewport.right = viewport.left + win.width()
    viewport.bottom = viewport.top + win.height()

    var bounds = this.offset()
    bounds.right = bounds.left + this.outerWidth()
    bounds.bottom = bounds.top + this.outerHeight()

    return !(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom)
  }

  $('.counter_item_number').counterUp({
    delay: 10,
    time: 1000,
  })

  setTimeout(function() {
    $('.loader_holder').fadeOut(200)
  }, 2500)

  // custom select
  const selectItems = document.querySelectorAll('.customSelect')

  selectItems.forEach(item => {
    item.style.display = 'none'

    const select = item
    const div = document.createElement('div')
    const ul = document.createElement('ul')
    const bruceLi = document.createElement('li')

    div.classList.add('select')
    item.before(div)
    div.append(item, ul)
    ul.append(bruceLi)

    bruceLi.textContent = item.children[0].textContent

    for (let i = 0; i < item.children.length; i++) {
      const li = document.createElement('li')
      li.innerText = item.children[i].textContent
      li.dataset.value = item.children[i].value
      li.addEventListener('click', function() {
        bruceLi.textContent = this.textContent
        select.value = this.dataset.value
      })
      ul.append(li)
    }
  })

  const allSelects = document.querySelectorAll('.select')

  document.addEventListener('click', function(e) {
    let target = e.target.closest('.select')

    allSelects.forEach(item => {
      if (item != target) item.classList.remove('active')
    })

    if (target) target.classList.toggle('active')
  })

  $('#close_contact_form_popup').click(function() {
    $('#contact_form_popup').fadeOut(200)
  })

  $('#close_email_form_popup').click(function() {
    $('#email_form_popup').fadeOut(200)
    $('#email_form_email_error').text('')
  })

  $('.requires_contact').click(function(e) {
    e.preventDefault()
    $('#contact_form_popup').fadeIn(200)
  })

  $('.free').click(function(e) {
    e.preventDefault()
    let categoryId = $(this).data('category')
    $('#popup_category_' + categoryId).fadeIn(200)
  })

  $('.requires_email').click(function(e) {
    e.preventDefault()
    let categoryId = $(this).data('category')
    if (localStorage.getItem('desing_download_email') === 'true') {
      $('#popup_category_' + categoryId).fadeIn(200)
    } else {
      $('#email_form_popup').data('category', categoryId)
      $('#email_form_popup').fadeIn(200)
    }
  })

  $('#email_form_submit_btn').click(function(e) {
    e.preventDefault()
    let categoryId = $('#email_form_popup').data('category')
    $('#email_form_email_error').text('')
    let form = $(this)
      .parent()
      .parent()
    let method = form.attr('method')
    let url = form.attr('action')
    let email = $('#email_form_email_input').val()
    let csrfToken = form.find('input[name=_token]').val()
    $.ajax({
      url: url,
      type: method,
      data: {
        email: email,
        _token: csrfToken,
      },
      success: function() {
        localStorage.setItem('desing_download_email', 'true')
        $('#email_form_popup').fadeOut(200)
        $('#email_form_email_error').text('')
        $('#email_form_email_input').val('')
        $('#popup_category_' + categoryId).fadeIn(200)
      },
      error: function(data) {
        let error = data.responseJSON.errors ? data.responseJSON.errors.email[0] : 'Error'
        $('#email_form_email_error').text(error)
      },
    })
  })

  $('.close_popup').click(function() {
    let categoryId = $(this).data('category')
    $('#popup_category_' + categoryId).fadeOut(200)
  })
})
